<template>
  <b-form>
    <b-form-group label="Первый водитель">
      <b-form-select id="carrier" v-model="form.firstDriverId" :state="formErrors.firstDriverId">
        <b-form-select-option :value="null" :key="null">Выберите первого водителя</b-form-select-option>
        <b-form-select-option v-for="driver in drivers" :value="driver.id" :key="driver.id">
          {{ driver.lastname + ' ' + driver.name + ' ' + driver.middleName + ' - ' + driver.passNumber }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group label="Второй водитель">
      <b-form-select id="carrier" v-model="form.secondDriverId" :state="formErrors.secondDriverId">
        <b-form-select-option :value="null" :key="null">Выберите второго водителя</b-form-select-option>
        <b-form-select-option v-for="driver in drivers" :value="driver.id" :key="driver.id">
          {{ driver.lastname + ' ' + driver.name + ' ' + driver.middleName + ' - ' + driver.passNumber }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import START from "@/forms/START_TRIP";
import {mapState} from "vuex";

const START_INIT_FORM = () => ({
  tripId: null,
  firstDriverId: null,
  secondDriverId: null
})

export default {
  name: "Start",
  props: {
    init: { required: false, default: () => START_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      selectedRestrict: 'exceptSeats',
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      drivers: state => state.Drivers.items
    }),
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
      Object.assign(this.form, form)
    },
    resetForm() {
      Object.assign(this.form, START_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, START)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return
      let form = { ...this.form }
      this.$emit('onSave', form)
    }
  }
}
</script>

<style scoped>

</style>