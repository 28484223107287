<template>
  <div>
    <b-col cols="12">
      <h2>Управление рейсами</h2>
    </b-col>
    <b-col cols="12">
      <b-row>
        <b-col>
          <b-card class="mb-4 mt-4">
            <a v-b-toggle href="#order-filter" class="filter-title" @click.prevent>Фильтр поездок</a>
            <b-collapse id="order-filter">
              <b-form-group label="Маршрут" class="mt-4">
                <b-form-select :value="filters.routeId" @change="setFilterByKey('routeId', $event)">
                  <b-form-select-option :value="null">Все</b-form-select-option>
                  <b-form-select-option v-for="route in routes" :value="route.id" :key="route.id">
                    {{ route.number }} - {{ route.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="Перевозчик">
                <b-form-select :value="filters.carrierId" @change="setFilterByKey('carrierId', $event)">
                  <b-form-select-option :value="null">Все</b-form-select-option>
                  <b-form-select-option v-for="carrier in carriers" :value="carrier.id" :key="carrier.id">
                    {{ carrier.carrierName }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Начиная с даты" class="mb-0">
                      <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" :value="filters.dateFrom" @input="setFilterByKey('dateFrom', $event)" reset-button/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Заканчивая датой" class="mb-0">
                      <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" :value="filters.dateTo" @input="setFilterByKey('dateTo', $event)" reset-button/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Время отправления" class="d-flex">
                <b-form-timepicker id="timepicker" label-no-time-selected="" :value="filters.time" @input="setFilterByKey('time', $event)" reset-button></b-form-timepicker>
              </b-form-group>
              <div class="d-flex">
                <b-button variant="primary" @click="load">Применить</b-button>
              </div>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" class="mb-4 d-flex justify-content-end">
      <b-button variant="primary" @click="$refs.createModal.show()">Добавить рейс</b-button>
    </b-col>
    <create ref="createModal"/>
    <update ref="updateModal"/>
    <PeriodicTripsForm ref="periodicTrip"/>
    <b-modal
        id="modal-start-trip"
        ref="modalStartTrip"
        title="Начать рейс"
        hide-footer
        size="lg"
    >
      <start ref="startForm" btn-title="Начать" btn-variant="primary" @onSave="startTrip"/>
    </b-modal>
    <TripReport ref="tripReport"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="trips" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-left d-flex">
                <template>
                  <i v-if="data.item.status === 'planned'" class="zmdi zmdi-directions-bus action-icon blue-icon" v-b-tooltip.hover title="Отправить в рейс" @click="tripToggleStatus(data.item.id)"></i>
                  <i v-if="data.item.status === 'in_trip'" class="zmdi zmdi-directions-bus action-icon green-icon" v-b-tooltip.hover title="Завершить рейс" @click="finishTrip(data.item.id)"></i>
                  <i v-else-if="data.item.status === 'finished'" class="zmdi zmdi-flag action-icon red-icon" v-b-tooltip.hover title="Завершен"></i>
                </template>
                <a :href="'/trip-report/' + data.item.id" target="_blank">
                  <div v-b-tooltip.hover title="Печать посадочной ведомости">
                    <b-icon class="action-icon" icon="printer-fill" variant="primary" font-scale="1"></b-icon>
                  </div>
                </a>
                <div v-b-tooltip.hover title="Редактировать" @click="update(data.item)">
                  <b-icon class="action-icon" icon="pencil-square" variant="primary" font-scale="1"></b-icon>
                </div>
                <div v-if="data.item.periodicTripId" v-b-tooltip.hover @click="showPeriodicTrip(data.item.periodicTripId)" title="Регулярное расписание">
                  <b-icon class="action-icon" icon="clock" variant="primary" font-scale="1"></b-icon>
                </div>
                <div v-b-tooltip.hover title="Удалить" @click="remove(data.item)">
                  <b-icon class="action-icon" icon="trash-fill" variant="primary" font-scale="1"></b-icon>
                </div>
              </div>
            </template>
          </b-table>
          <b-pagination-nav
              :link-gen="linkGen"
              :value="page"
              @page-click="load"
              :number-of-pages="maxPages"
              use-router
          ></b-pagination-nav>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import Start from './WorkWithTrips/Start'
import Create from './WorkWithTrips/Create'
import Update from './WorkWithTrips/Update'
import TripReport from "./WorkWithTrips/TripReport"
import { default as PeriodicTripsForm } from './WorkWithPeriodicTrips/Update'
import periodicTripsApi from "@/api/periodicTrips.api";

export default {
  name: "WorkWithTrips",
  data() {
    return {
      currentPage: 1,
      errors: {},
      fields: [
        {
          key: 'carrier.carrierName',
          label: 'Перевозчик'
        },
        {
          key: 'startTime',
          label: 'Отправление',
          formatter: (value) => moment(value).format('DD.MM.YYYY HH:mm:ss')
        },
        {
          key: 'route.name',
          label: 'Маршрут'
        },
        {
          key: 'departureTime',
          labelHtml: "Время отправления <br>планово/фактически",
          formatter: (value, key, item) => moment(item.startTime).format('HH:mm:ss')
        },
        {
          key: 'route.time',
          labelHtml: "Время прибытия <br>планово/фактически",
          formatter: (value, key, item) => moment(item.startTime).add(value, 'minutes').format('HH:mm:ss')
        },
        {
          key: 'busInfo',
          labelHtml: "Автобус",
          formatter: (value, key, item) => item.bus.mark + ' ' + item.bus.model
        },
        {
          key: 'seatsCount',
          labelHtml: "Мест"
        },
        {
          key: 'pay',
          labelHtml: "Продажа"
        },
        {
          key: 'paidCount',
          labelHtml: "Продано",
          formatter: (value, key, item) => item.tickets.length
        },
        {
          key: 'freeSeatsCount',
          labelHtml: "Свободно"
        },
        {
          key: 'bus.gosNumber',
          labelHtml: "Г/Н"
        },
        {
          key: 'actions',
          label: ""
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('Trips/load', { page: 1 })
  },
  methods: {
    setFilterByKey(key, value) {
      this.$store.commit('Trips/SET_FILTER_BY_KEY', { key, value })
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    load(event, page) {
        event.preventDefault()
        this.$store.dispatch('Trips/load', { page })
    },
    update(form) {
      this.$refs.updateModal.show({
        ...form,
        startDate: moment(form.startTime).format('YYYY-MM-DD'),
        startTime: moment(form.startTime).format('HH:mm')
      })
    },
    showPeriodicTrip(periodicTripId) {
      this.$store.dispatch('App/startLoad')
      const refs = this.$refs;
      periodicTripsApi.get(periodicTripId).then(response => {
        console.log(response.data.form)
        refs.periodicTrip.show({
          ...response.data.form,
          startDate: moment(response.data.form.startTime).format('YYYY-MM-DD'),
          startTime: moment(response.data.form.startTime).format('HH:mm')
        })
      }).finally(() => {
        this.$store.dispatch('App/stopLoad')
      })
    },
    tripToggleStatus(tripId) {
      console.log(tripId)
      this.$refs.modalStartTrip.show()
      setTimeout(() => {
        this.$refs.startForm.fillForm({
         tripId
        })
      }, 0);
    },
    startTrip(form) {
      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Trips/start', form)
          .then(() => {
            this.$refs.modalStartTrip.hide()
            this.$refs.startForm.resetForm()
            this.$bvToast.toast('Рейс был успешно отправлен.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
    finishTrip(tripId) {
      if (!confirm('Вы действительно хотите завершить рейс?')) {
        return;
      }

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Trips/finish', {
        tripId
      })
          .then(() => {
            this.$refs.modalStartTrip.hide()
            this.$refs.startForm.resetForm()
            this.$bvToast.toast('Рейс был успешно завершен.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
    remove(trip) {

      console.log(trip.id)

      if (!confirm('Вы действительно хотите удалить рейс?')) {
        return;
      }

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Trips/remove', trip.id)
        .then(() => {
          this.$bvToast.toast('Рейс был успешно удален.', {
            title: 'Успех',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true
          })
        })
        .finally(() => {
          this.$store.dispatch('App/stopLoad')
        })
    }
  },
  computed: {
    ...mapState({
      loading: state => state.Trips.loading,
      trips: state => state.Trips.items,
      filters: state => state.Trips.filters,
      page: state => state.Trips.page,
      maxPages: state => state.Trips.maxPages,

      carriers: state => state.Carriers.items,
      routes: state => state.Routes.items,
    })
  },
  components: {
    Start,
    Create,
    Update,
    TripReport,
    PeriodicTripsForm
  }
}
</script>

<style scoped>
  .filter-title {
    font-size: 16px;
    font-weight: 600;
    color: #3e5569;
  }
</style>