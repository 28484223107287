<template>
  <b-modal
      ref="modal"
      title="Посадочная ведомость"
      hide-footer
      size="xl"
  >
    <b-overlay :show="loading">
      <div v-html="report"></div>
    </b-overlay>
  </b-modal>
</template>

<script>
import ReportsApi from '@/api/reports.api'

export default {
  name: "TripReport",
  data() {
    return {
      loading: false,
      report: ''
    }
  },
  methods: {
    reset() {
      this.report = ''
    },
    show(tripId) {
      console.log(tripId)
      this.reset()
      this.$refs.modal.show()
      this.load(tripId)
    },
    load(tripId) {
      this.loading = true

      ReportsApi.tripReport({ tripId }).then(response => {
          if (response.data.report) {
            this.report = response.data.report
          }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
</style>